import React, {useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { regexPassword } from '../utils';
import avatar from './images/wildfire_logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faRectangleXmark} from "@fortawesome/free-solid-svg-icons";
import ExchangePlatformSection from './ExchangePlatformSection';
import BinanceComponent from './BinanceComponent';
import BitpandaComponent from './BitpandaComponent';


import {
    Paper,
    Container,
    Link,
    Stack,
    Button,
    Box,
    Divider,
    Select,
    MenuItem,
    Avatar,
    Typography,
    TextField,
    FilledInput,
    InputAdornment,
    IconButton,
    InputLabel,
    FormControl,
    FormHelperText,
} from '@mui/material';
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import PaypalSubscribe from "./Paypal_Subscribe";


function Signup() {
    const [exchange, setExchange] = useState('');
    const [values, setValues] = useState({
        country: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        repeatPassword: '',
        showPassword: false,
        showRepeatPassword: false,
    });
    const [errors, setErrors] = useState({
        email: false,
        username: false,
        phone: false,
        password: false,
        repeatPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
    });

    const [platforms, setPlatforms] = useState([{ values: {exchange: ''}, errors: {} }]);
    const [showInfo, setShowInfo] = useState(false);
    const alertRef = useRef(null);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);

    const handlePaymentSuccess = () => {
        setPaymentSuccessful(true);
    };

    const handleChange = (index) => (fieldName, value) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms[index] = {
            ...updatedPlatforms[index],
            values: {
                ...updatedPlatforms[index].values,
                [fieldName]: value,
            },
        };
        setPlatforms(updatedPlatforms);
    };
    const handleChangePlatform = (field, value) => {
        // Update the state with the new value
        setExchange(value);
    };
    const handleClose = () => {
        setShowInfo(false);
        setExchange("");
    };
    const handleHelpClick = () => {
        setShowInfo(true);
    };
    const handleChangeField = (fieldName) => (event) => {
        const currValue = event.target.value
        switch (fieldName) {
            case 'email':
                validator.isEmail(currValue)
                    ? setErrors({ ...errors, email: false })
                    : setErrors({ ...errors, email: true })
                break

            case 'password':
                regexPassword.test(currValue)
                    ? setErrors({ ...errors, password: false })
                    : setErrors({ ...errors, password: true })
                break

            case 'repeatPassword':
                currValue === values.password
                    ? setErrors({ ...errors, repeatPassword: false })
                    : setErrors({ ...errors, repeatPassword: true })
                break
        }
        setValues({ ...values, [fieldName]: event.target.value })
    }
    const handleShowPassword = (showPasswordField) => {
        setValues({
            ...values,
            [showPasswordField]: !values[showPasswordField],
        });
    };

    const handleAddPlatform = () => {
        setPlatforms([...platforms, { values: {exchange: ''}, errors: {} }]);
    };

    const handleRemovePlatform = (indexToRemove) => {
        setPlatforms(platforms.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate each platform before submitting the form
        const isValidPlatforms = platforms.every((platform) => {
            return (
                platform.values.exchange &&
                platform.values.initial_spot &&
                platform.values.initial_margin &&
                platform.values.initial_future &&
                platform.values.api_key &&
                platform.values.secret_key
            );
        });

        if (!isValidPlatforms) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'All fields are required',
            });
            return;
        }

        try {
            const requestBody = {
                email: values.email,
                password: values.password,
                username: values.username,
                country: values.country,
                phone: values.phone,
                platforms: platforms.map((platform) => ({
                    exchange: platform.values.exchange,
                    initial_spot: platform.values.initial_spot,
                    initial_margin: platform.values.initial_margin,
                    initial_future: platform.values.initial_future,
                    api_key: platform.values.api_key,
                    secret_key: platform.values.secret_key,
                })),
            };

            const res = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!res.ok) {
                const error = await res.json();
                return setErrors({
                    ...errors,
                    fetchError: true,
                    fetchErrorMsg: error.msg,
                });
            }

            const data = await res.json();
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: data.msg,
            });

            setValues({
                email: '',
                username: '',
                country:'',
                phone: '',
                password: '',
                repeatPassword: '',
                showPassword: false,
                showRepeatPassword: false,
            });

            // Reset all fields for exchange
            const resetPlatforms = platforms.map(platform => ({ values: { exchange: '',initial_spot:'',initial_margin:'',initial_future:'',api_key:'',secret_key:''  }, errors: {} }));
            setPlatforms(resetPlatforms);

        } catch (error) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'There was a problem with our server, please try again later',
            });
        }
    };

    return (
        <>
            <Container sx={{ marginTop: 'calc(100vh - 45%)' }} maxWidth='sm'>
                <Paper elevation={6}>
                    <Container
                        maxWidth='sm'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                        }}>
                        <Avatar
                            alt="avatar"
                            src={avatar}
                            sx={{ width: 70, height: 70 }}
                        />
                        <h2>Register a new account</h2>
                    </Container>

                    <Stack
                        component='form'
                        onSubmit={handleSubmit}
                        noValidate
                        spacing={2}
                        sx={{ bgcolor: '#f5f5f6', padding: '50px', maxWidth: '100%' }}
                    >
                        <Container
                            maxWidth='sm'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10
                            }}>
                            <h5>Personal information</h5>
                        </Container>
                        <Divider />
                        {/* Personal Information Section */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                variant='filled'
                                type='email'
                                label='Email'
                                value={values.email || ''}
                                onChange={handleChangeField('email')}
                                error={errors.email}
                                helperText={errors.email && 'Please insert a valid email address'}
                            />
                            <TextField
                                variant='filled'
                                type='username'
                                label='Username'
                                value={values.username || ''}
                                onChange={handleChangeField('username')}
                                error={errors.username}
                                helperText={errors.username && 'Please insert a valid username'}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="flex-end">
                            <FormControl variant="filled" sx={{ width: "30%" }}>
                                <InputLabel id="country-label">Select Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    value={values.country || ''}
                                    onChange={handleChangeField('country')}
                                >
                                    <MenuItem value="US">United States</MenuItem>
                                    <MenuItem value="UK">United Kingdom</MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                    <MenuItem value="AU">Australia</MenuItem>
                                    <MenuItem value="DE">Germany</MenuItem>
                                    <MenuItem value="FR">France</MenuItem>
                                    <MenuItem value="IT">Italy</MenuItem>
                                    <MenuItem value="ES">Spain</MenuItem>
                                    <MenuItem value="JP">Japan</MenuItem>
                                    <MenuItem value="CN">China</MenuItem>
                                    <MenuItem value="IN">India</MenuItem>
                                    <MenuItem value="BR">Brazil</MenuItem>
                                    <MenuItem value="ZA">South Africa</MenuItem>
                                    <MenuItem value="RU">Russia</MenuItem>
                                    <MenuItem value="MX">Mexico</MenuItem>
                                    <MenuItem value="KR">South Korea</MenuItem>
                                    <MenuItem value="NL">Netherlands</MenuItem>
                                    <MenuItem value="CH">Switzerland</MenuItem>
                                    <MenuItem value="SE">Sweden</MenuItem>
                                    <MenuItem value="SG">Singapore</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                variant='filled'
                                type='phone'
                                label='Phone'
                                value={values.phone || ''}
                                onChange={handleChangeField('phone')}
                                error={errors.phone}
                                helperText={errors.phone && 'Please insert a valid phone number'}
                                sx={{ width: "70%" }}
                            />
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-field'>Password</InputLabel>
                                <FilledInput
                                    id='password-field'
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password || ''}
                                    onChange={handleChangeField('password')}
                                    error={errors.password}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showPassword')}
                                                edge='end'>
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                <FormHelperText error={errors.password}>
                                    Password must be at least 8 characters, have one symbol, 1
                                    uppercase letter, 1 lowercase and 1 digit
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-repeat-field'>
                                    Repeat password
                                </InputLabel>
                                <FilledInput
                                    id='password-repeat-field'
                                    type={values.showRepeatPassword ? 'text' : 'password'}
                                    value={values.repeatPassword || ''}
                                    onChange={handleChangeField('repeatPassword')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showRepeatPassword')}
                                                edge='end'>
                                                {values.showRepeatPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {errors.repeatPassword && (
                                    <FormHelperText error={errors.repeatPassword}>
                                        Password must be the same as above
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        {/* Trading Information Section */}
                        <Container
                            maxWidth="xs"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '40px',
                            }}
                        >
                            <h5>
                                Trading Information&nbsp;
                                <FontAwesomeIcon onClick={handleHelpClick} icon={faCircleQuestion} bounce style={{ color: "#000000" }} />
                            </h5>
                            {showInfo && (
                                <div className="overlay" style={{ zIndex: '1000' }}>
                                    <div className="alert" ref={alertRef}>
                                                    <span className="close-icon" onClick={handleClose} style={{ position: 'absolute', top: '0', right: '0', padding: '2px' }}>
            <FontAwesomeIcon icon={faRectangleXmark} size="xl" style={{ color: "#808080" }} />
          </span>
                                        <div className="row-19 border-radius" style={{ position: 'relative' }}>
                                            <h2>Help Center</h2>
                                            <Divider />
                                            <div className="col-12 row-20">
                                                <Stack>
                                                    <TextField
                                                        select
                                                        label="Select Exchange Platform"
                                                        value={exchange}
                                                        onChange={(event) => handleChangePlatform('exchange', event.target.value)}
                                                        variant="filled"
                                                    >
                                                        <MenuItem value="binance">Binance</MenuItem>
                                                        <MenuItem value="bitpanda">Bitpanda</MenuItem>
                                                    </TextField>
                                                </Stack>
                                                <Divider />
                                                {exchange === 'binance' && <BinanceComponent />}
                                                {exchange === 'bitpanda' && <BitpandaComponent />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Container>
                        <Divider />
                        {/* Render existing platform sections */}
                        {platforms.map((platform, index) => (
                            <div key={index}>
                                <ExchangePlatformSection
                                    values={platform.values}
                                    errors={platform.errors}
                                    handleChange={(field, value) => handleChange(index)(field, value)}
                                />
                                <Button
                                    onClick={() => handleRemovePlatform(index)}
                                    style={{ float: 'right', fontSize: '6px' }}
                                >
                                    Remove Platform
                                </Button>
                            </div>
                        ))}
                        {/* Add Platform Button */}
                        <Button onClick={handleAddPlatform} style={{ fontSize: '12px' }}>
                            Add Platform
                        </Button>
                        <PaypalSubscribe onSuccess={handlePaymentSuccess}/>
                        {!isPaymentSuccessful ? (
                            <Stack alignItems="center" spacing={2}>
                                <Typography color="red">
                                    Please, complete subscription before to proceed
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack alignItems="center" spacing={2}>
                                <Typography color="green">
                                    Payment successful! You can now register.
                                </Typography>
                            </Stack>
                        )}
                        {/* Register Button and Login Link */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pt: 2,
                            }}
                        >
                            <Button
                                variant='contained'
                                size='large'
                                type='submit'
                                sx={{
                                    minWidth: '70%',
                                }}
                            >
                                REGISTER
                            </Button>
                        </Box>
                        {errors.fetchError && (
                            <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
                        )}
                        <Divider />
                        <Typography paragraph align='center'>
                            Already have an account?{' '}
                            <Link component={RouterLink} to='/'>
                                Login here
                            </Link>
                        </Typography>
                    </Stack>
                </Paper>
            </Container>
        </>
    );
}

export default Signup;
