import React, { useEffect } from 'react';
import { loadScript } from '@paypal/paypal-js';

const PaypalSubscribe = ({ onSubscriptionSuccess }) => {
    useEffect(() => {
        const initializePayPal = async () => {
            try {
                const paypalSdk = await loadScript({
                    'client-id': 'ARGC-kno6poFvgCL4yk3pO6HxBG6I2mbKyqyle6H1XLr0XfpYm8dHy2F5zcFUAVx5yC1BbGx31H5Z8vC',
                    vault: true,
                    intent: 'subscription',
                });

                if (!paypalSdk) {
                    console.error('Failed to load PayPal SDK');
                    alert('An error occurred loading PayPal. Please try again.');
                    return;
                }

                paypalSdk.Buttons({
                    style: {
                        shape: 'rect',
                        color: 'black',
                        layout: 'horizontal',
                        label: 'subscribe',
                    },
                    createSubscription: function (data, actions) {
                        return actions.subscription.create({
                            plan_id: 'P-1ES70193JW282635YM6CCKUY',
                        });
                    },
                    onApprove: function (data, actions) {
                        console.log('Subscription approved:', data);
                        alert(`Subscription successful! ID: ${data.subscriptionID}`);
                        onSubscriptionSuccess(true); // Notify parent of success
                    },
                    onError: function (err) {
                        console.error('PayPal button error:', err);
                        alert('An error occurred with the PayPal button.');
                        onSubscriptionSuccess(false); // Notify parent of failure
                    },
                }).render('#paypal-button-container-P-1ES70193JW282635YM6CCKUY');
            } catch (error) {
                console.error('PayPal SDK loading error:', error);
                alert('An error occurred initializing PayPal.');
            }
        };

        initializePayPal();
    }, [onSubscriptionSuccess]);

    return (
        <div>
            <div id="paypal-button-container-P-1ES70193JW282635YM6CCKUY"></div>
        </div>
    );
};

export default PaypalSubscribe;
