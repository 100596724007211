const indicatorNameMap = {
    // 15m Indicators
    "rsi6_15m_wf": "RSI (6)",
    "rec_rsi6_bull_15m_wf": "RSI 6 Bullish",
    "rsi14_15m_wf": "RSI (14)",
    "rec_rsi14_bull_15m_wf": "RSI 14 Bullish",
    "rsi30_15m_wf": "RSI (30)",
    "rec_rsi30_bull_15m_wf": "RSI 30 Bullish",
    "rec_golden_cross_15m_wf": "Golden Cross",
    "rec_death_cross_15m_wf": "Death Cross",
    "rec_ema510_15m_wf": "EMA 0510",
    "rec_ema1020_15m_wf": "EMA 1020",
    "rec_ema1550_15m_wf": "EMA 1550",
    "rec_ema50200_15m_wf": "EMA 50200",
    "rec_ema719_15m_wf": "EMA 719",
    "rec_ema921_15m_wf": "EMA 921",
    "rec_ema725_15m_wf": "EMA 725",
    "rec_ema2599_15m_wf": "EMA 2599",
    "rec_doji_15m_wf": "Doji",
    "rec_hammer_15m_wf": "Hammer",
    "rec_hanging_man_15m_wf": "Hanging Man",
    "rec_morning_star_15m_wf": "Morning Star",
    "rec_evening_star_15m_wf": "Evening Star",
    "rec_shooting_star_15m_wf": "Shooting Star",
    "rec_inverted_hammer_15m_wf": "Inverted Hammer",
    "rec_three_inside_up_15m_wf": "3 Inside Up",
    "rec_three_inside_down_15m_wf": "3 Inside Down",
    "rec_three_white_soldiers_15m_wf": "3 White Soldiers",
    "rec_three_black_crows_15m_wf": "3 Black Crows",
    "rec_engulfing_15m_wf": "Engulfing",
    "rec_vwma_14_15m_wf": "VWMA (14)",
    "rec_macd_15m_wf": "MACD (12,26,9)",
    "rec_psar_5_15m_wf": "PSAR (5)",
    "rec_psar_10_15m_wf": "PSAR (10)",
    "rec_psar_15_15m_wf": "PSAR (15)",
    "rec_kdj_3_15m_wf": "KDJ (3)",
    "rec_kdj_6_15m_wf": "KDJ (6)",
    "rec_kdj_9_15m_wf": "KDJ (9)",
    "rec_dmi_14_15m_wf": "DMI (14)",

    // 1h Indicators
    "rsi6_1h_wf": "RSI (6)",
    "rec_rsi6_bull_1h_wf": "RSI 6 Bullish",
    "rsi14_1h_wf": "RSI (14)",
    "rec_rsi14_bull_1h_wf": "RSI 14 Bullish",
    "rsi30_1h_wf": "RSI (30)",
    "rec_rsi30_bull_1h_wf": "RSI 30 Bullish",
    "rec_golden_cross_1h_wf": "Golden Cross",
    "rec_death_cross_1h_wf": "Death Cross",
    "rec_ema510_1h_wf": "EMA 0510",
    "rec_ema1020_1h_wf": "EMA 1020",
    "rec_ema1550_1h_wf": "EMA 1550",
    "rec_ema50200_1h_wf": "EMA 50200",
    "rec_ema719_1h_wf": "EMA 719",
    "rec_ema921_1h_wf": "EMA 921",
    "rec_ema725_1h_wf": "EMA 725",
    "rec_ema2599_1h_wf": "EMA 2599",
    "rec_doji_1h_wf": "Doji",
    "rec_hammer_1h_wf": "Hammer",
    "rec_hanging_man_1h_wf": "Hanging Man",
    "rec_morning_star_1h_wf": "Morning Star",
    "rec_evening_star_1h_wf": "Evening Star",
    "rec_shooting_star_1h_wf": "Shooting Star",
    "rec_inverted_hammer_1h_wf": "Inverted Hammer",
    "rec_three_inside_up_1h_wf": "3 Inside Up",
    "rec_three_inside_down_1h_wf": "3 Inside Down",
    "rec_three_white_soldiers_1h_wf": "3 White Soldiers",
    "rec_three_black_crows_1h_wf": "3 Black Crows",
    "rec_engulfing_1h_wf": "Engulfing",
    "rec_vwma_14_1h_wf": "VWMA 14",
    "rec_macd_1h_wf": "MACD (12,26,9)",
    "rec_psar_5_1h_wf": "PSAR (5)",
    "rec_psar_10_1h_wf": "PSAR (10)",
    "rec_psar_15_1h_wf": "PSAR (15)",
    "rec_kdj_3_1h_wf": "KDJ (3)",
    "rec_kdj_6_1h_wf": "KDJ (6)",
    "rec_kdj_9_1h_wf": "KDJ (9)",
    "rec_dmi_14_1h_wf": "DMI (14)",

    // 1d Indicators
    "rsi6_1d_wf": "RSI (6)",
    "rec_rsi6_bull_1d_wf": "RSI 6 Bullish",
    "rsi14_1d_wf": "RSI (14)",
    "rec_rsi14_bull_1d_wf": "RSI 14 Bullish",
    "rsi30_1d_wf": "RSI (30)",
    "rec_rsi30_bull_1d_wf": "RSI 30 Bullish",
    "rec_golden_cross_1d_wf": "Golden Cross",
    "rec_death_cross_1d_wf": "Death Cross",
    "rec_ema510_1d_wf": "EMA 0510",
    "rec_ema1020_1d_wf": "EMA 1020",
    "rec_ema1550_1d_wf": "EMA 1550",
    "rec_ema50200_1d_wf": "EMA 50200",
    "rec_ema719_1d_wf": "EMA 719",
    "rec_ema921_1d_wf": "EMA 921",
    "rec_ema725_1d_wf": "EMA 725",
    "rec_ema2599_1d_wf": "EMA 2599",
    "rec_doji_1d_wf": "Doji",
    "rec_hammer_1d_wf": "Hammer",
    "rec_hanging_man_1d_wf": "Hanging Man",
    "rec_morning_star_1d_wf": "Morning Star",
    "rec_evening_star_1d_wf": "Evening Star",
    "rec_shooting_star_1d_wf": "Shooting Star",
    "rec_inverted_hammer_1d_wf": "Inverted Hammer",
    "rec_three_inside_up_1d_wf": "3 Inside Up",
    "rec_three_inside_down_1d_wf": "3 Inside Down",
    "rec_three_white_soldiers_1d_wf": "3 White Soldiers",
    "rec_three_black_crows_1d_wf": "3 Black Crows",
    "rec_engulfing_1d_wf": "Engulfing",
    "rec_vwma_14_1d_wf": "VWMA 14",
    "rec_macd_1d_wf": "MACD (12,26,9)",
    "rec_psar_5_1d_wf": "PSAR (5)",
    "rec_psar_10_1d_wf": "PSAR (10)",
    "rec_psar_15_1d_wf": "PSAR (15)",
    "rec_kdj_3_1d_wf": "KDJ (3)",
    "rec_kdj_6_1d_wf": "KDJ (6)",
    "rec_kdj_9_1d_wf": "KDJ (9)",
    "rec_dmi_14_1d_wf": "DMI (14)"
};
export default indicatorNameMap;