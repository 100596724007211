import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, Tooltip, ResponsiveContainer } from 'recharts';

const LineChartTotal = ({ total }) => {
	// State variables to store fetched data and the selected data type
	const [data, setData] = useState([]);
	const [selectedDataType, setSelectedDataType] = useState('total');
	const [extremes, setExtremes] = useState({ min: null, max: null, current: null });
	const [chartKey, setChartKey] = useState(0); // Force re-render by updating key

	// Persistent refs for rendering flags and screen size tracking
	const minRendered = useRef(false);
	const maxRendered = useRef(false);
	const screenSize = useRef({ width: window.innerWidth, height: window.innerHeight });

	// Fetch data from API based on selected data type
	useEffect(() => {
		fetchData(selectedDataType);
	}, [selectedDataType]);

	// Reset min/max rendering flags only when screen size changes
	useEffect(() => {
		const handleResize = () => {
			const currentWidth = window.innerWidth;
			const currentHeight = window.innerHeight;

			// Only trigger logic if screen size has changed
			if (
				currentWidth !== screenSize.current.width ||
				currentHeight !== screenSize.current.height
			) {
				screenSize.current = { width: currentWidth, height: currentHeight };
				minRendered.current = false;
				maxRendered.current = false;
				setChartKey((prevKey) => prevKey + 1); // Force chart re-render
			}
		};

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const fetchData = async (dataType) => {
		try {
			let response;
			switch (dataType) {
				case 'total':
					response = await fetch('/api/totalhistory');
					break;
				default:
					break;
			}
			const responseData = await response.json();

			// Format the fetched data
			const formattedData = responseData.map((item) => ({
				username: item.username,
				data: item.data.map((d) => ({
					date: new Date(d.date),
					value: d.value,
				})),
			}));

			setData(formattedData); // Set data state

			// Calculate extremes for all data points
			const allValues = responseData.flatMap((item) =>
				item.data.map((d) => d.value).filter((v) => v !== 0)
			);
			const min = Math.min(...allValues);
			const max = Math.max(...allValues);
			const current = allValues[allValues.length - 1];

			setExtremes({ min, max, current });
			setChartKey((prevKey) => prevKey + 1); // Force chart re-render

			// Reset rendering flags for min and max
			minRendered.current = false;
			maxRendered.current = false;
		} catch (error) {
			console.error(error);
		}
	};

	// Function to render enhanced dots for highest and lowest points
	const renderDot = (props) => {
		const { cx, cy, value } = props;

		// Render the min value
		if (value === extremes.min && !minRendered.current) {
			minRendered.current = true;
			return (
				<g>
					<text
						x={cx}
						y={cy + 12} // Adjusted for readability
						fill="#000"
						fontSize={8}
					>
						$ {value}
					</text>
				</g>
			);
		}

		// Render the max value
		if (value === extremes.max && !maxRendered.current) {
			maxRendered.current = true; // Mark max as rendered
			return (
				<g>
					<text
						x={cx - 15}
						y={cy - 6} // Adjusted for readability
						fill="#000"
						fontSize={8}
					>
						$ {value}
					</text>
				</g>
			);
		}

		// Otherwise, render nothing
		return null;
	};

	// Render the line chart with fetched data
	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					flex: '1 1 calc(25% - 10px)',
					margin: '2px',
					fontSize: '14px',
					textAlign: 'center',
					backgroundColor: '#fff',
				}}
			>
				<div style={{ fontSize: '10px', fontWeight: 'bold' }}>
					$ {total.toFixed(2)}
				</div>
				<div style={{ fontSize: '6px', color: '#333' }}>Total</div>
			</div>
			<ResponsiveContainer width="100%" height={120}>
				<LineChart
					key={chartKey}
					margin={{ top: 0, right: 10, bottom: 2, left: 10 }} // Add extra margin
				>
					<Tooltip />

					{/* Render lines using fetched data */}
					{data.map((entry, index) => (
						<Line
							key={`data-${index}`}
							dataKey="value"
							name={entry.username}
							data={entry.data}
							stroke={selectedDataType === 'total' ? '#888' : '#888'}
							strokeWidth={2}
							type="monotone"
							dot={renderDot}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default LineChartTotal;
