import React, { useState, useEffect } from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';

const ProgressionBarPerformanceReview = ({ stability, range_ratio, price_position, performance_score }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const calculateData = (recommendations) => {
            return recommendations.map(recommendation => ({
                name: recommendation.name,
                positive: (recommendation.value * 100).toFixed(2),  // Convert value between 0 and 1 to percentage (0-100)
                negative: (100 - (recommendation.value * 100)).toFixed(2)  // The complement to get the negative value
            }));
        };

        const recommendations = [
            { name: 'Stability', value: stability },
            { name: 'Range Ratio', value: range_ratio },
            { name: 'Price Position', value: price_position },
            { name: 'Performance', value: performance_score }
        ];

        setData(calculateData(recommendations));
    }, [stability, range_ratio, price_position, performance_score]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        backgroundColor: '#CCC',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                    }}
                >
                    <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{payload[0]?.payload?.name}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color, margin: 0 }}>
                            {entry.dataKey.charAt(0).toUpperCase() + entry.dataKey.slice(1)}: {entry.value}%
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={data}> {/* Changed layout to vertical */}
                    <CartesianGrid vertical={true} />
                    <XAxis type="category" dataKey="name" tick={{ fontSize: 7 }} /> {/* XAxis now is for numeric values */}
                    <YAxis type="number" hide /> {/* YAxis now is for categories */}
                    <Tooltip content={<CustomTooltip />} /> {/*Removing hover information */}
                    <Bar dataKey="positive" fill="#000" stackId="a" label={{ position: 'inside', formatter: (value) => `${value}%` }} stroke="#000" strokeWidth={1} />
                    <Bar dataKey="negative" fill="#FFF" stackId="a" stroke="#000" strokeWidth={1} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProgressionBarPerformanceReview;
